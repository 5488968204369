import '../../../service/domain/vip.js'
(function () {
  controller.$inject = ['$scope', 'vip'];

  function controller($scope, _vip) {
    $scope.collection = [];
    $scope.preloader = false;

    $scope.preloader = true;
    _vip
      .collection()
      .then((a) => {
        $scope.collection = a.result;
      })
      .catch((e) => {})
      .finally(() => {
        $scope.preloader = false;
      });
  }

  new Controller('vipList', controller);
})();

(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanVipList${i}`, 'vipList');
  });
})();
