import '../../service/car-promo/car-promo.js';
import '../../service/rx/system$.js';
import {filter} from 'rxjs/operators';

(function () {
  controller.$inject = ['$scope', 'carPromo', 'system$'];

  const component = {
    name: 'batmanCarPromo',
  };
  function controller($scope, _carPromo, _system$) {
    $scope.tournament = null;
    getTournamentData();

    function getTournamentData() {
      _carPromo.data().then(({ result }) => {
        $scope.tournament = result;
        if (result.current_stage === 2) {
          _carPromo.leaderBoard({ max_players: 50 }).then(({ result }) => {
            $scope.tournament = { ...$scope.tournament, ...result };
          });
        }
      });
    }
    _system$
      .pipe(filter((messages) => messages.action === 'statusChanged'))
      .subscribe(() => {
        getTournamentData();
      });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
