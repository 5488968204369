import '../../service/car-promo/car-promo.js';

(function () {
  'use strict';

  controller.$inject = ['$scope', 'carPromo'];

  function controller($scope, _carPromo) {
    $scope.carData = {};

    _carPromo.data().then(({ result }) => {
      $scope.carData = result;
    });
  }

  new Controller('carWidget', controller);
})();

(function () {
  'use strict';

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanCarWidget${i}`, 'carWidget');
  });
})();
