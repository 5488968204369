import '../service/validator/iban.js';
import '../service/validator/postcode.js';
import '../service/validator/password.js';
import '../service/validator/cpf.js';
import '../service/validator/addressCA.js';
import '../service/validator/account-validator.js';

(function () {
  'use strict';

  const directive = { name: 'validateAs' };
  controller.$inject = ['iban', 'postcode', 'password', 'cpf', 'addressCA', 'accountValidator'];

  function controller(_iban, _postcode, _password, _cpf, _addressCA, _accountValidator) {
    const link = (scope, element, attrs, ngModel) => {
      const type = attrs[directive.name] || null;
      const validator = {};
      const asyncValidator = {};

      switch (type) {
        case 'iban':
          validator.type = 'iban';
          validator.method = _iban.check;
          break;

        case 'cpf':
          validator.type = 'cpf';
          validator.method = _cpf.check;
          break;

        case 'postcode':
          // -validator.type = 'postcode';
          // -validator.method = _postcode.check;
          validator.customValidators = _postcode.customValidators;
          break;

        case 'password':
          // validator.type = 'password';
          // validator.method = _postcode.check;
          validator.customValidators = _password.customValidators;
          break;

        case 'addressCA':
          validator.type = 'addressCA';
          validator.method = _addressCA.check;
          break;

        case 'phone':
          phoneFormatter();
          asyncValidator.type = 'phone';
          asyncValidator.method = _accountValidator.asyncCheckPhone;
          break;

        case 'login':
          asyncValidator.type = 'login';
          asyncValidator.method = _accountValidator.asyncCheckLogin;
          break;

        default:
          validator.type = null;
          break;
      }

      if (typeof validator.customValidators === 'function') validator.customValidators(scope, element, attrs, ngModel);

      if (validator.type) {
        ngModel.$validators[validator.type] = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? validator.method(value) : true;
        };
      }

      if (asyncValidator.type) {
        ngModel.$asyncValidators[asyncValidator.type] = (modelValue, viewValue) => {
          const value = modelValue || viewValue;
          return value ? asyncValidator.method(value) : true;
        };
      }

      function phoneFormatter() {
        element.on('input', () => {
          let newValue = `+${element.val().replace(/[^0-9]/g, '')}`;
          ngModel.$setViewValue(newValue);
          ngModel.$render();

          ngModel.$validate();
        });
      }
    };

    return { restrict: 'A', require: '?ngModel', link };
  }

  app.directive(directive.name, controller);
})();
