import '../../service/domain/http.js';
import '../../service/mobile-app/version.js';
import '../../service/configs/config.js';
import '../../service/configs/trustly.js';
/**
 *
 *
 *          countries()
 *
 *
 */

(function () {
  'use strict';

  controller.$inject = ['http', '$q', '$state', 'version', 'config', 'trustly.config', '$http'];

  function controller(_http, $q, $state, _version, _config, _trustly_config, $http) {
    return {
      mode: {
        paynplay: _trustly_config.enabled,
      },

      systemInfo: {},

      info() {
        const defer = $q.defer(),
          alias = 'systemInfo',
          config = {
            params: {},
            cache: true,
            meta: { type: 'api', alias },
          };

        const testDate = window.localStorage.getItem('test-date') || '';
        if (testDate.length) {
          config.params.today = testDate;
        }

        _http
          .get(alias, config)
          .then(({ data }) => {
            this.systemInfo = data.result;
            defer.resolve(data);
          })
          .catch((e) => defer.reject(e));

        return defer.promise;
      },
      getSystemInfo() {
        if (Object.keys(this.systemInfo).length === 0) {
          return this.info().then((info) => {
            return info.result;
          });
        }
        return new Promise((resolve) => resolve(this.systemInfo));
      },
      questions() {
        const defer = $q.defer(),
          alias = 'userQuestions',
          config = {
            params: {},
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      mobile: {
        currentVersion() {
          const defer = $q.defer(),
            alias = 'currentVersion',
            config = {
              params: {},
              cache: true,
              meta: {
                type: 'api',
                alias,
              },
            };

          _http.get(alias, config).then(
            (answer) => {
              defer.resolve(answer.data);
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },

        updateCheck() {
          const defer = $q.defer(),
            alias = 'currentVersion', //todo: need new method
            config = {
              params: {
                //-                                limit: 1000
              },
              cache: true,
              meta: {
                type: 'api',
                alias,
              },
            };

          _http.get(alias, config).then(
            (answer) => {
              if (_version.update(answer.data.result[_config.mobileConfig.platform])) {
                $state.go('update.home', { params: answer.data.result[_config.mobileConfig.platform] });
              } else {
                defer.resolve(answer.data);
              }
            },
            (answer) => {
              defer.reject(answer.data);
            }
          );

          return defer.promise;
        },
      },

      countries() {
        const defer = $q.defer(),
          alias = 'countryList',
          config = {
            params: {
              limit: 1000,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      avatars() {
        const defer = $q.defer(),
          alias = 'avatarList',
          config = {
            params: {
              limit: 1000,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          function (answer) {
            defer.resolve(answer.data);
          },
          function (answer) {
            defer.reject(answer.data);
          }
        );

        return defer.promise;
      },

      json(options) {
        const defer = $q.defer();

        $http
          .get(options.url)
          .then((a) => {
            defer.resolve(a.data);
          })
          .catch((e) => {
            defer.reject(e);
          });

        return defer.promise;
      },

      nationality() {
        const defer = $q.defer(),
          alias = 'nationalityList',
          config = {
            params: {
              limit: 1000,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );
        return defer.promise;
      },

      professions() {
        const defer = $q.defer(),
          alias = 'professionList',
          config = {
            params: {
              limit: 1000,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );
        return defer.promise;
      },

      document_list(options) {
        const defer = $q.defer(),
          alias = 'userDocumentList',
          config = {
            params: {
              limit: 1000,
              ...options,
            },
            cache: true,
            meta: {
              type: 'api',
              alias,
            },
          };

        _http.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          }
        );
        return defer.promise;
      },
    };
  }

  app.factory('system', controller);
})();
