var $_CONFIG = $_CONFIG || {};

$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'fezbet';
$_CONFIG.sportWidgetSkinName = 'fezbet';

$_CONFIG.useSvgSprite = false;
$_CONFIG.scripts = $_CONFIG.scripts || [];
$_CONFIG.domain = 'https://fezbet.devv2.space';
$_CONFIG.siteDomain = 'https://fezbet.com';
$_CONFIG.cdn = 'https://joxi.imgsrcdata.com';
$_CONFIG.gsapVersion = '3';

$_CONFIG.pwa = false;
$_CONFIG.appName = 'Fezbet';
//$_CONFIG.themeColor = '#1E2737';

$_CONFIG.siteName = 'fezbet';
$_CONFIG.mobile = false;

$_CONFIG.customScripts = [];

$_CONFIG.sportBanners = {
  bottomRight: {
    iframe: `/joxi/js/crab/sdk/views/widget-sport.html?build=${Date.now()}`,
    options: {
      height: '486px',
    }
  }
};

$_CONFIG.googleAnalytics = { appId: 'UA-160252894-1 ', trackAs: 'fezbet.com '}

$_CONFIG.symplify = true;

//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.
$_CONFIG.places = {
    banner: {
      australian_open: `'season_sport_tournament'`,
      wimbledon: `'tennis_banner'`,
    },
    slider: {
        gamehall1: `'game_hall'`,
        home1: `'main_slider'`
    },
    promo: {
        registration: `'registration'`,
        home1: `'home-promo'`

    },
    icons: {
        footer1: `'footer-payments'`,
        partners1: `'footer-partners'`,
        paynplay: `'paynplay_pay'`
    }
};

export { $_CONFIG };
