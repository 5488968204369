(function () {

    'use strict';

    const directive = {
        name: 'animation'
    };

    controller.$inject = ['$state'];

    function controller($state) {
        function link(scope, element, attrs) {

            attrs.$observe(directive.name, val => {
                if (val === 'true') {
                    setAnimation();
                }
            })

            function setAnimation() {
                const timeLines = {};
                scope.$parent.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
                    const name = toParams.name ? toParams.name : fromParams.name;
                    !!toParams.name ? timeLines[name].play() : timeLines[name].reverse();
                });


                element = document.querySelector('.anim-tournament__container');
                gsap.registerPlugin(ScrollToPlugin);

                const list = [...element.querySelectorAll('.anim-tournament')];
                list.forEach(tournament => {
                    const btnPlay = tournament.querySelector('.anim-tournament__play');
                    const btnReverse = tournament.querySelector('.anim-tournament__reverse');
                    const infoBefore = tournament.querySelector('.anim-tournament__info-before');
                    const infoAfter = tournament.querySelector('.anim-tournament__info-after');
                    const games = tournament.querySelector('.anim-tournament__games');
                    const smallBg = tournament.querySelector('.anim-tournament__small-bg');
                    const name = btnPlay.getAttribute('href').split('/').pop();

                    const tl = gsap.timeline({
                        paused: true,
                        defaults: {
                            ease: 'power1.easeIn'
                        }
                    });
                    timeLines[name] = tl;
                    const otherElems = [...list].filter(el => el !== tournament);

                    const idx = list.indexOf(tournament);
                    const prevEl = list[idx - 1];
                    const nextEl = list[idx + 1];
                    const isPrev = idx === list.length - 1;

                    tl
                        .to(element, {
                            duration: 0,
                            position: 'relative',
                            overflow: 'auto'
                        })

                        // block positioning
                        .to(isPrev ? prevEl : nextEl, {
                            duration: 0,
                            marginTop: () => {
                                return isPrev ? 0 : tournament.clientHeight;
                            },
                            marginBottom: () => {
                                return isPrev ? tournament.clientHeight : 0;
                            },
                        })
                        .to(tournament, {
                            duration: 0,
                            position: 'absolute',
                            top: tournament.offsetTop,
                            left: Math.floor((element.offsetWidth - tournament.offsetWidth) / 2),
                            zIndex: 10,
                        })

                        // fading
                        .to(otherElems, {
                            duration: .66,
                            opacity: 0,
                            pointerEvents: 'none',
                        })

                        //scrolling to top
                        .to(tournament, {
                            duration: .3,
                            top: 0
                        }, '-=.16')
                        .to(window, {
                            duration: .3,
                            scrollTo: {
                                y: 0
                            }
                        }, '<')

                        .addLabel('opening', '+=.16')

                        // opening

                        .to(tournament, {
                            height: tournament.scrollHeight,
                            duration: .76
                        }, 'opening')

                        //list max height
                        .to(element, {
                            height: () => tournament.scrollHeight + parseInt(window.getComputedStyle(element).paddingBottom),
                            overflow: 'hidden'
                        }, '<')
                        .to(smallBg, {
                            duration: .16,
                            opacity: 0
                        }, '>') 

                        // hiding content
                        .to(infoBefore, {
                            duration: .16,
                            opacity: 0
                        }, 'opening')
                        .to(infoBefore, {
                            duration: 0,
                            display: 'none'
                        }, '>')
                        // showing content 
                        .to(infoAfter, {
                            display: 'inherit',
                            duration: 0
                        }, '>')
                        .to(infoAfter, {
                            duration: .16,
                            opacity: 1
                        }, '>')                                             
                        .to(games, {
                            duration: .33,
                            opacity: 1
                        }, '>')
                        .to(btnReverse, {
                            duration: .16,
                            opacity: 1,
                            pointerEvents: 'all'
                        }, 'opening+=.16')

                    if (name === $state.params.name) {
                        tl.progress(1);
                    }


                })
            }
        }


        return {
            restrict: 'A',
            link
        };
    }

    app.directive(directive.name, controller);

})();