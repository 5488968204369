import state from '../../../app/service/domain/state.js';
import popup from '../../../app/service/domain/popup.js';
import game from '../../../app/service/domain/game.js';

import $ from 'jquery';
import gsap from 'gsap';

(function () {
  'use strict';

  const directive = { name: 'random' };

  controller.$inject = ['state', 'popup', 'game', '$timeout'];

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  function controller(_state, _popup, _game, $timeout) {
    function compile() {
      return function ($scope, $element, $attrs, $ctrl, $transclude) {
        let popupClosed = false;
        let games = [];
        const gamesImgs = [];
        const gamesTimelines = [];
        let previousNode = $element[0];

        const time = 1;
        const config = {
          paused: true,
          defaults: { duration: 0.2, ease: 'none' },
        };

        $scope.preloader = true;

        _game
          .collection({ count: 10, random: true })
          .then((answer) => {
            games = answer.result;
            const counter = games.length;
            let decoded = 0;
            games.forEach((game, index) => {
              $transclude($scope.$new(true), (clone, scope) => {
                const elem = $(clone)[0];
                gamesImgs.push(elem);
                elem.src = `${game.poster_thumbnail}`;
                elem.alt = `${game.name}`;
                $(clone).insertAfter(previousNode);
                previousNode = clone;
                elem.decode().then(() => {
                  decoded++;
                  if (decoded === counter) {
                    $timeout(() => {
                      $scope.preloader = false;
                    });
                    setTimeline();
                  }
                });
              });
            });
          })
          .catch((e) => {
            _popup.close();
          });

        function setTimeline() {
          gamesImgs.forEach((img, index) => {
            gsap.set(img, { x: 0, rotationY: 0, scale: 0.83 });
            if (index < 3) {
              gamesTimelines.push(getTimeline(config, img, games[index]).play(0.1 + 0.4 * index));
            } else if (index > gamesImgs.length - 4) {
              const endPoint = index - (gamesImgs.length - 4);
              gamesTimelines.push(
                getTimeline(
                  {
                    ...config,
                    delay: (index - 3) * 0.4 + 0.3,
                  },
                  img,
                  games[index],
                  endPoint,
                ).play(),
              );
            } else {
              gamesTimelines.push(
                getTimeline(
                  {
                    ...config,
                    delay: (index - 3) * 0.4 + 0.3,
                  },
                  img,
                  games[index],
                ).play(),
              );
            }
          });
        }

        function getTimeline(config, animatable, item, endPoint = 0) {
          const tl = gsap.timeline(config);
          tl.set(animatable, { x: 0, rotationY: 0, scale: 0.83 })
            .to(animatable, { x: 40, duration: 0.1 })
            .to(animatable, { x: 80, rotationY: 22 })
            .to(animatable, { x: 0, rotationY: 0 })
            .to(animatable, { x: -80, rotationY: -35 })
            .to(animatable, { x: -40, rotationY: 0 })
            .to(animatable, { x: 0, duration: 0.1 })
            .to(animatable, { scale: 1, duration: 0.4 }, 0.1)
            .to(animatable, { scale: 0.83, duration: 0.4 }, 0.5)

          if ( isSafari) {
            tl
              .set(animatable, { translateZ: 200 }, 0)
              .set(animatable, { translateZ: 600 }, 0.3)
              .set(animatable, { translateZ: 500 }, 0.6)
              .set(animatable, { translateZ: 400 }, 0.8)
              .set(animatable, { translateZ: 100 }, 0.9)
              .set(animatable, { translateZ: 0 }, 1);
          } else {
            tl
              .set(animatable, { css: { zIndex: 1 } }, 0)
              .set(animatable, { css: { zIndex: 5 } }, 0.3)
              .set(animatable, { css: { zIndex: 4 } }, 0.6)
              .set(animatable, { css: { zIndex: 3 } }, 0.8)
              .set(animatable, { css: { zIndex: 0 } }, 0.9)
              .set(animatable, { css: { zIndex: -1 } }, 1);
          }

          tl.timeScale(time);
          if (endPoint) {
            const tl1 = gsap.timeline({ defaults: { ease: 'none' }, delay: tl.delay() });
            tl1.timeScale(time);
            switch (endPoint) {
              case 1:
                return tl1
                  .fromTo(tl, 1, { progress: 0 }, { progress: 1 })
                  .fromTo(tl, 0.1, { progress: 0 }, { progress: 0.1 });
              case 2:
                return tl1.fromTo(tl, 0.9, { progress: 0 }, { progress: 0.9 });
              case 3:
                tl1.eventCallback('onComplete', launchGame, [item]);
                return tl1.fromTo(tl, 0.5, { progress: 0 }, { progress: 0.5 });
            }
          }
          return tl;
        }

        function launchGame(game) {
          $timeout(() => {
            if (game && !popupClosed) {
              _state.goto('realGame', { name: game.alias });
              _popup.close();
            }
          }, 2000);
        }

        $scope.$on('$destroy', () => {
          popupClosed = true;
        });
      };
    }

    return {
      restrict: 'A',
      transclude: 'element',
      compile,
    };
  }

  app.directive(directive.name, controller);
})();
