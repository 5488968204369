import config from "../../app/service/configs/config";

(function () {

  'use strict';

  const directive = { name: 'zetIframeQuizUrl' };

  controller.$inject = ['config'];

  function controller(_config) {
    function link( scope, element ){
      const langForUrl = ["de", "it", "pl", "fi"].includes(_config.currentLang) ? _config.currentLang : '';
      const baseUrl = "https://matespromo.com/mateadmin/fezbet/";
      scope.url = baseUrl + langForUrl;
    }

    return {
      restrict: 'A',
      link
    };
  }

  app.directive( directive.name, controller );

})();
