import '../../service/domain/sport.js';
import '../../service/configs/config.js';
import '../../service/domain/popup.js';
import '../../service/rx/user$.js';
import '../../service/rx/system$.js';
import '../../service/rx/timer$.js';
import '../../service/domain/state.js';
import '../../service/domain/banner.js';
import '../../service/domain/sportBannerConfig.js';
import '../../service/domain/system.js';
import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil, bufferCount } from 'rxjs/operators'
// let BIA = {};

(function () {
  'use strict';

  const name = 'lindaSportField';
  const hash = 'BIAcc';
  let request = null;
  let session_id = null;
  let subscription = null;

  controller.$inject = [
    '$state',
    'sport',
    'config',
    'popup',
    'user$',
    'system$',
    'timer$',
    'state',
    'banner',
    'sportBannerConfig',
    'system',
  ];

  function controller(
    $state,
    _sport,
    _config,
    _popup,
    _user$,
    _system$,
    _timer$,
    _state,
    _banner,
    _sportBannerConfig,
    _system,
  ) {
    function link(scope, element, attrs) {
      const scrollElement = document.querySelector('[scroll-element]');
      const _scrollElement$ = scrollElement ? new fromEvent(scrollElement, 'scroll') : new Subject();

      const destroy$ = new Subject();

      scope.preloader = false;

      let options = {};
      let BIA = {};

      attrs.$set('id', hash);

      action($state.params);
      scope.$on('$stateChangeSuccess', () => {
        action($state.params);
      });

      function action(val) {
        element.addClass('loading');
        const params = Object.assign({}, val);
        delete params.lang;
        if (typeof BIA.setParam !== 'function') {
          init(params);
        } else {
          change(params);
        }
      }

      function change(pm) {
        for (const pr in pm) {
          if (pm.hasOwnProperty(pr)) {
            BIA.setParam(pr, pm[pr], true);
          }
        }
        BIA.setParam('page', pm.page);
      }

      async function init(pm) {
        scope.preloader = true;
        const systemInfo = await _system.info();
        const sport = await _sport.launch({ code: 'sport' });

        let banners;
        _sportBannerConfig
          .altenarFromConfig()
          .then((data) => {
            banners = data;
          })
          .catch((e) => console.error(e))
          .finally(() => {
            scope.preloader = false;
            final();
          });

        session_id = sport.result.session_id;

        const final = () => {
          options = Object.assign(
            {},
            sport.result.launch_options,
            {
              mobile: _config.device.mobile, // @todo if 0 - betslip fixed not work
              // intervalDelay: 0,
              fixedBottom: _config.sport.fixedBottom,
              fixedTop: _config.sport.fixedTop,
              showLoginBoxCallback,
              insufficientbalancecallback,
              statechangeCallback,
              betslipoddscountchangecallback,
              loadCallback,
              isHashMode: $state.params?.isHashMode || false,

              banners,
            },
            _config.sport.initializationParams,
            pm,
          );

          if (systemInfo?.result?.country.code === 'CA') {
            options.oddstype = 'american';
          }

          if (window.AltenarBetslipOddIds) {
            options.betslipOddIds = [];
            options.betslipOddIds.push(...window.AltenarBetslipOddIds);
          }
          window.AltenarBetslipOddIds = undefined;

          const ident = setInterval(() => {
            if (typeof BetinactionAPI === 'function') {
              BIA = new BetinactionAPI(`#${hash}`, options);
              clearInterval(ident);
            }

            if (typeof AltenarSportsbook === 'function') {
              BIA = new AltenarSportsbook(`#${hash}`, options);
              clearInterval(ident);
            }
          }, 20);
        };
      }

      _sport.betslipUpdate$.pipe(takeUntil(destroy$)).subscribe((id) => BIA.setParam('betslipoddids', id));

      if (_config.jurisdiction === 'sweden' && !subscription) {
        subscription = _timer$
          .pipe(
            takeUntil(destroy$),
            bufferCount(10)
          )
          .subscribe(() => {
            if (typeof session_id !== null && !request) {
              request = true;
              _sport
                .check({ session_id, code: 'sport' })
                .then(() => {})
                .catch((e) => {
                  if (e.messages[0].code === 2806) {
                    _state.goto('home');
                    subscription.unsubscribe();
                  }
                })
                .finally(() => (request = false));
            }
          });
      }

      _system$
        .pipe(
          takeUntil(destroy$),
          filter(({ action }) => action === 'login')
        )
        .subscribe(() => {
          _sport
            .launch({ code: 'sport' })
            .then((a) => {
              options = a.result.launch_options;
              BIA.setParam('token', options.token);
            })
            .catch((e) => console.error(e));
        });

      _system$
        .pipe(
          takeUntil(destroy$),
          filter(({ action }) => action === 'logout')
        )
        .subscribe(() => BIA.setParam('token', ''));

      _scrollElement$.pipe(takeUntil(destroy$)).subscribe(() => BIA.setParam('fixedBottom', _config.sport.fixedBottom));

      function showLoginBoxCallback() {
        _popup.open({ name: 'login' });
      }

      function insufficientbalancecallback() {
        if (!_config.sport.insufficientbalancecallback) {
          return;
        }
        _popup.open({ name: 'sport-low-balance' });
      }

      function scrollToTop() {
        Array.prototype.find.call(document.querySelectorAll('*'), ({ scrollTop }) => scrollTop > 0)?.scroll(0, 0);
      }

      function statechangeCallback(state) {
        const params = Object.assign(
          {},
          {
            sportids: null,
            catids: null,
            champids: null,
            tabs: null,
            eventid: null,
          },
          state,
        );

        params.name = params.page;

        if (params.name === 'betslip') scrollToTop();

        delete params.page;

        $state.go('.', params, { notify: false });
      }

      function loadCallback() {
        element.removeClass('loading');
      }

      const betslipoddscountchangecallback = (oddIds) => {
        if (window.altenarWSDK?.set) {
          window.altenarWSDK.set({
            oddIds,
            clearBetSlip: true,
          });
          return;
        }
      };

      scope.$on('$destroy', () => {
        BIA.destroy();

        destroy$.next();
        destroy$.unsubscribe();
      });
    }

    return {
      restrict: 'A',
      link, //            scope: {}
    };
  }

  app.directive(name, controller);
})();
