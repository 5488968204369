import '../../service/domain/game.js';
import '../../service/domain/state.js';
import '../../service/domain/popup.js';
import '../../service/rx/game$.js';
import { filter, debounceTime } from 'rxjs/operators';

(function () {
  'use strict';

  const component = { name: 'lindaGameRandomLaunch' };

  controller.$inject = ['$scope', 'game', 'state', 'popup', '$timeout', 'game$'];

  function controller($scope, _game, _state, _popup, $timeout, _game$) {
    const config = {
      random: true,
      category: '',
    };
    let subscription;

    this.$onInit = function () {
      config.category = this.category;
      config.delay = this.delay;
      config.count = this.count || 1;
      $scope.collection = [];
      $scope.preloader = true;

      _game
        .collection(config)
        .then((answer) => {
          _game$.next({ type: 'random', game: answer.result[0] });
          $scope.collection = answer.result;
        })
        .catch((e) => {
          _popup.close();
        })
        .finally(() => {
          $scope.preloader = false;
        });

      subscription = _game$
        .pipe(
          filter((item) => item.type === 'random'),
          debounceTime(config.delay)
        )
        .subscribe((item) => {
          if (item.game) {
            _state.goto('realGame', { name: item.game.alias });
          }
          _popup.close();
        });
    };

    this.$onDestroy = function () {
      subscription.unsubscribe();
    };
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      category: '<',
      count: '<',
      delay: '<',
    },
  });
})();
