(function () {
  controller.$inject = ['$scope'];
  function controller($scope) {
    this.$onChanges = function () {
      const name = this.name || 'data';
      $scope[name] = this.data;
    };
  }
  new Controller('container', controller, {
    name: '<',
    data: '<',
  });
})();
(function () {
  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    new Batman(`batmanContainer${i}`, 'container');
  });
})();
