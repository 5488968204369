import '../../../service/helper.js';
import '../../../service/domain/content-buffer.js';
import '../../../service/domain/state.js';
import '../../../service/domain/popup.js';
(function () {
  'use strict';

  const component = { name: 'lindaFastRegistrationForm' };

  controller.$inject = ['$scope', 'helper', 'contentBuffer', 'state', '$state', 'popup'];

  function controller($scope, _helper, _contentBuffer, _state, $state, _popup) {
    $scope.formData = {};

    $scope.submit = _helper.form.validator((form) => {
      const state = _state.get('registration');

      if (state.page) {
        // todo if page
        //                $state.goto( state.name,  )
      } else {
        _popup.open({ name: state.name, content: { formData: $scope.formData } });
      }
    });
  }

  app.component(component.name, {
    controller,
    template: app.getTU(component.name),
  });
})();
