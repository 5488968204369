import '../../service/domain/account.js';
import '../../service/domain/popup.js';
import '../../service/domain/modal.js';
import '../../service/analytics/analytics.js';
import '../../service/helper.js';
import '../../service/domain/state.js';
import '../../service/configs/analytic-events.js';
(function () {
  'use strict';

  const component = { name: 'lindaLoginForm' };

  controller.$inject = [
    '$scope',
    'account',
    'popup',
    'modal',
    '$state',
    'analytics',
    'helper',
    'state',
    'analyticsEvents',
  ];

  function controller($scope, _account, _popup, _modal, $state, analytics, _helper, _state, _analyticsEvents) {
    const loginMode = 'login';
    const emailMode = 'email';

    $scope.rememberedLogin = localStorage.getItem('rememberedLogin') || '';
    $scope.rememberedAvatar = localStorage.getItem('rememberedAvatar') || '';
    $scope.rememberedName = localStorage.getItem('rememberedName') || '';

    $scope.preloader = false;
    $scope.isLoginSuccessful = false;
    $scope.mode = null;
    $scope.credentials = {
      login: $scope.rememberedLogin === '' ? undefined : $scope.rememberedLogin,
    };

    $scope.sendOpenReg = (area) => {
      analytics.send(_analyticsEvents.createAccountButton, `login_form_${area}`);
    };

    let successRedirectState = null;

    const login = (form) => {
      $scope.preloader = true;

      if ($scope.mode) {
        $scope.mode === loginMode ? delete $scope.credentials.email : delete $scope.credentials.login;
      }

      _account
        .login($scope.credentials)
        .then((answer) => {
          _popup.close();

          $scope.isLoginSuccessful = true;

          if (typeof _popup.cb === 'function') {
            _popup.cb();
            _popup.cb = null;
          }

          if (successRedirectState && $state.get(successRedirectState)) {
            $state.go(successRedirectState);
          }

          if (!successRedirectState) {
            _state.readyToRedirectAfterAuth$.next();
          }
        })
        .catch((error) => {
          $scope.preloader = false;
          // incorrect one-time code case
          if (error.messages[0].code === 2091) {
            delete $scope.credentials.oneTimeCode;
            delete $scope.credentials.backupCode;
          }
          if (error.messages[0].code === 1152) {
            // if two factor auth needed
            loginByTwoFactor();
          }
        });
    };
    const loginByTwoFactor = () => {
      _modal
        .open('linda-two-factor-login')
        .then((code) => {
          $scope.preloader = true;
          $scope.credentials = {
            ...$scope.credentials,
            ...code,
          };
          login();
        })
        .catch((err) => console.error(err));
    };

    this.$onInit = function () {
      successRedirectState = this.successRedirectState;
    };

    this.$onChanges = function () {
      $scope.mode = $scope.rememberedName ? loginMode : this.mode;
    };

    $scope.changeMode = () => {
      $scope.mode = $scope.mode === loginMode ? emailMode : loginMode;
    };

    $scope.resetLoginCredentials = () => ($scope.credentials.login = '');

    $scope.submit = _helper.form.validator(login);
  }

  app.component(component.name, {
    controller: controller,
    template: app.getTU(component.name),
    bindings: {
      successRedirectState: '<',
      mode: '<',
    },
  });
})();
