import angular from 'angular';
import 'angular-translate';
import '@uirouter/angularjs';
import '@uirouter/angularjs/release/stateEvents';
import 'angular-animate';
import 'angular-scroll';
import '../libs/scrollSpyModule.js';
import 'angular-messages';
import 'angular-ui-mask';
import 'oclazyload';

(function () {
  window.app = (function () {
    const dependency = [
      'ui.router',
      'ui.router.state.events',
      'pascalprecht.translate',
      'ngAnimate',
      'duScroll',
      'templates',
      'scrollSpyModule',
      'ngMessages',
      'ui.mask',
      'oc.lazyLoad',
    ].filter((item) => {
      let result = false;
      try {
        const t = angular.module(item);
        result = true;
      } catch (e) {}

      return result;
    });

    const app = angular.module('app', dependency);
    window.Controller = app.controller;

    return app;
  })();
})();
