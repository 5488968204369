(function () {
  if (
    typeof window.$_CONFIG.sentry === 'object' &&
    window.$_CONFIG.sentry.enabled === true &&
    window.$_DRAGON.env === 'production'
  ) {
    const dsn = window.$_CONFIG.sentry.dsn || `https://${window.$_CONFIG.sentry.key}@${window.location.hostname}/sentry/${window.$_CONFIG.sentry.id}`;
    const version = window.$_CONFIG.buildVersion || '0.0.1';
    Sentry.init({
      dsn: dsn,
      release: `${window.$_CONFIG.sentry.project}@${version}`,
      environment: (location && location.hostname) || 'dev',
      integrations: [new Sentry.BrowserTracing()],
      sampleRate: 1.0,
      tracesSampleRate: 0.2,
    });
  }
})();
