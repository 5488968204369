import '../../service/domain/api.js';
import '../../service/rx/notification$.js';
import '../../service/rx/system$.js';
(function () {
  'use strict';

  controller.$inject = ['$http', 'api', '$q', 'notification$', 'system$', '$window', '$httpParamSerializer'];

  function controller($http, _api, $q, _notification$, _system$, $window, $httpParamSerializer) {
    function parseMessages(arr) {
      if (typeof arr !== 'undefined') {
        arr.forEach((item) => {
          _notification$.next(item);
        });
      }
    }

    function sessionDead(response) {
      if (response.status === 403)
        _system$.next({
          action: 'forced logout',
        });
    }

    function removeEmptyParams(params) {
      const result = {};
      Object.entries(params).map(([key, value]) => {
        if (value) {
          result[key] = value;
        }
      });
      return result;
    }

    function Cache(name) {
      this.prefix = name;

      this.get = (url) => {
        let r = {};
        try {
          r = JSON.parse($window.localStorage.getItem(`${this.prefix}-${url}`));
        } catch (e) {}
        return r;
      };

      this.set = (url, data) => {
        // -$window.localStorage.setItem( `${this.prefix}-${url}`, JSON.stringify( data ) );
      };
    }

    const cache = new Cache('linda-cache-v1');

    return {
      get(alias, config) {
        const defer = $q.defer();
        const url = typeof alias === 'object' ? `${_api.ask(alias.name)}${alias.param}` : _api.ask(alias);

        if (config.meta) {
          // api as request type by default
          if (config.meta.type === undefined) config.meta.type = 'api';

          if (config.meta.absenceEmptyParams) {
            config.params = removeEmptyParams(config.params);
          }

          // get from inapp cache if configured
          if (config.meta.cache === true && config.meta.bullshit === true) {
            const answer = cache.get(`${url}?${$httpParamSerializer(config.params)}`);

            if (answer) {
              defer.resolve(answer);
            }
          }
        }

        $http.get(url, config).then(
          (answer) => {
            cache.set(`${answer.config.url}?${$httpParamSerializer(answer.config.params)}`, { data: answer.data });
            parseMessages(answer.data.messages);
            defer.resolve(answer);
          },
          (answer) => {
            sessionDead(answer);
            parseMessages(answer.data.messages);
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      post(alias, data, config) {
        const defer = $q.defer();
        const url = typeof alias === 'object' ? `${_api.ask(alias.name)}/${alias.param}` : _api.ask(alias);

        if (config.meta && config.meta.type === undefined) config.meta.type = 'api';

        $http.post(url, data, config).then(
          (answer) => {
            parseMessages(answer.data.messages);
            defer.resolve(answer);
          },
          (answer) => {
            sessionDead(answer);
            parseMessages(answer.data.messages);
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      put(alias, data, config) {
        const defer = $q.defer();
        const url = _api.ask(alias);

        if (config.meta && config.meta.type === undefined) config.meta.type = 'api';

        $http.put(url, data, config).then(
          (answer) => {
            parseMessages(answer.data.messages);
            defer.resolve(answer);
          },
          (answer) => {
            sessionDead(answer);
            parseMessages(answer.data.messages);
            defer.reject(answer);
          },
        );

        return defer.promise;
      },

      delete(alias, config) {
        const defer = $q.defer();
        const url = _api.ask(alias);
        const headers = { 'Content-Type': 'application/json' };

        if (config.meta && config.meta.type === undefined) config.meta.type = 'api';
        if (config.data) config.headers = headers;

        $http.delete(url, config).then(
          (answer) => {
            parseMessages(answer.data.messages);
            defer.resolve(answer);
          },
          (answer) => {
            sessionDead(answer);
            parseMessages(answer.data.messages);
            defer.reject(answer);
          },
        );

        return defer.promise;
      },
      getData(alias, params, cache) {
        const defer = $q.defer();
        const config = {
          cache: cache || false,
          meta: {
            type: 'api',
            alias,
          },
          params,
        };
        this.get(alias, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
      postData(alias, params, data) {
        const defer = $q.defer();
        const config = {
          cache: false,
          meta: {
            type: 'api',
            alias,
          },
          params,
        };
        this.post(alias, data, config).then(
          (answer) => {
            defer.resolve(answer.data);
          },
          (answer) => {
            defer.reject(answer.data);
          },
        );

        return defer.promise;
      },
    };
  }

  app.factory('http', controller);
})();
