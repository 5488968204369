(function () {
  'use strict';

  const directive = { name: 'nativeDatepicker' };

  controller.$inject = ['$filter', 'birthdayDatepicker'];

  function controller($filter, _birthdayDatepicker) {
    const link = ($scope, $element, $attrs, $ctrl) => {
      const params = $scope.$eval($attrs[directive.name]) || {};

      $ctrl.$parsers.push((value) => {
        return $filter('date')(value, 'yyyy-MM-dd');
      });

      $ctrl.$formatters.push((value) => {
        return new Date(value);
      });

      if (params.setDefaultFormData) {
        $element[0].addEventListener(
          'click',
          () => {
            $scope.formData.birthday = _birthdayDatepicker.maxDateValue;
          },
          { once: true },
        );
      }
    };

    return {
      require: 'ngModel',
      link,
    };
  }

  app.directive(directive.name, controller);
})();
